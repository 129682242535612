import React from "react";
import Icon, { Visa, MasterCard, Discover, Amex } from "../Icon";
import Flex from "../Flex";

const CardPaymentOptions = () => (
  <Flex width="40%" mt="16px">
    <Icon pr={1} as={Visa} height="24px" />
    <Icon pr={1} as={MasterCard} height="24px" />
    <Icon pr={1} as={Amex} height="24px" />
    <Icon pr={1} as={Discover} height="24px" />
  </Flex>
);

export default CardPaymentOptions;
