import React, { useState } from "react";
import styled from "styled-components";
import zendesk from "helpers/zendesk";
import Moment from "react-moment";
import { Redirect, Link, useHistory } from "react-router-dom";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { PageTitle } from "app/components/RouteComponents";
import { useDispatch } from "react-redux";
import { getUserSubscription } from "modules/user/actions";
import SpanLink from "app/components/SpanLink";
import Alert from "app/components/Alert";
import Div from "app/components/Div";
import { P, H3 } from "app/components/Typography";
import PoweredByStripeIcon from "app/components/PoweredByStripeIcon";
import CardPaymentOptions from "app/components/CardPaymentOptions";
import StripeCard from "app/components/StripeCard";
import PaypalButton from "app/components/PaypalButton";
import { Elements } from "@stripe/react-stripe-js";
import { routePadding } from "styles/variables";
import { loadStripe } from "@stripe/stripe-js";
import { PAYMENT_GATEWAYS, PAYMENT_METHOD_TYPES } from "constants/index";
import useUserSubscription from "hooks/Subscriptions/useUserSubscription";
import env from "helpers/env";
import { useInvoiceCollectPayment } from "./hooks";

const stripePromise = loadStripe(env("PUBLIC_STRIPE_PUBLISHABLE_KEY"));

const CreditWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0 0 0;
  margin-top: 8px;
`;

const Wrapper = styled.div`
  padding: ${routePadding}px;
`;

const CardContainer = styled.div`
  max-width: 400px;
`;

const BillingPastDue = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [payInvoiceError, setPayInvoiceError] = useState(null);

  const [data, userLoading, userError] = useUserSubscription();
  const [
    invoiceCollectPayment,
    invoiceCollectPaymentResults,
  ] = useInvoiceCollectPayment({
    onError: setPayInvoiceError,
    onCompleted: () => {
      dispatch(getUserSubscription());
      history.push("/account/subscription");
    },
  });

  const loading = userLoading || invoiceCollectPaymentResults.loading || !data;

  if (loading) {
    return <LoaderCentered />;
  }

  const { me: { primarySubscription: { invoiceDue, isPastDue } } = {} } =
    data || {};

  if (!isPastDue) {
    return <Redirect to="/account/subscription" />;
  }

  const handleUpdateStripePayment = token => {
    const variables = {
      invoiceId: invoiceDue.id,
      token: token.id,
      gatewayProvider: PAYMENT_GATEWAYS.stripe,
      type: PAYMENT_METHOD_TYPES.card,
    };

    invoiceCollectPayment({
      variables,
    });
  };

  const handleUpdateBraintreePayment = nonce => {
    const variables = {
      invoiceId: invoiceDue.id,
      token: nonce,
      gatewayProvider: PAYMENT_GATEWAYS.braintree,
      type: PAYMENT_METHOD_TYPES.paypal_express_checkout,
    };

    invoiceCollectPayment({
      variables,
    });
  };

  return (
    <Wrapper>
      <PageTitle>Update Billing Card</PageTitle>
      {userError && (
        <Alert variant="danger">
          There was a problem loading your subscription. Please try again or{" "}
          <SpanLink onClick={() => zendesk("webWidget", "open")}>
            contact us for help!
          </SpanLink>
        </Alert>
      )}
      {!userError && (
        <Div>
          <Div>
            <P>
              We’re having trouble processing the most recent payment on your
              account. Please update your card to complete the payment and
              continue your classes!
            </P>
            <P>
              If you&apos;d rather not continue your payments,{" "}
              <Link to="/account/cancel">cancel your account here</Link>.
            </P>
            <Div>
              <P>
                <span>Amount due:</span> ${invoiceDue.amountDue / 100}
              </P>
              <P>
                <span>Date due:</span>{" "}
                <Moment date={invoiceDue.dueDate} format="MMM D, YYYY" />
              </P>
              <P>
                <span>Payment attempts:</span> {invoiceDue.dunningAttemptsCount}
              </P>
              <P>
                <span>Next automated attempt:</span>{" "}
                <Moment date={invoiceDue.nextRetryAt} format="MMM D, YYYY" />
              </P>
            </Div>
            {!payInvoiceError && (
              <CardContainer>
                <CreditWrapper>
                  <H3>Credit/Debit</H3>
                  <PoweredByStripeIcon />
                </CreditWrapper>
                <CardPaymentOptions />
                <Elements stripe={stripePromise}>
                  <StripeCard
                    onError={setPayInvoiceError}
                    onSubmit={handleUpdateStripePayment}
                    buttonMessage="Update Card and Pay Invoice"
                    parentLoading={invoiceCollectPaymentResults.loading}
                  />
                </Elements>
                <PaypalButton
                  onSubmit={handleUpdateBraintreePayment}
                  onError={setPayInvoiceError}
                />
              </CardContainer>
            )}
            {payInvoiceError && (
              <Alert
                variant="danger"
                closeAlert={() => setPayInvoiceError(null)}
              >
                Please try again or{" "}
                <SpanLink onClick={() => zendesk("webWidget", "open")}>
                  contact us for help!
                </SpanLink>
              </Alert>
            )}
          </Div>
        </Div>
      )}
    </Wrapper>
  );
};

export default BillingPastDue;
