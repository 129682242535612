import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { CHECKOUT_ESTIMATE_QUERY } from "graphql/queries";

const useCheckoutEstimate = () => {
  const [
    getCheckoutEstimate,
    { data: { checkoutEstimate = null } = {}, loading, error, called },
  ] = useLazyQuery(CHECKOUT_ESTIMATE_QUERY);

  const { selectedPlan, coupon } = useSelector(store => store.checkout);
  useEffect(() => {
    // No need to fetch checkout estimate if no discounts will be applied
    if (!called && !coupon) {
      return;
    }
    getCheckoutEstimate({
      variables: {
        planInfo: {
          id: selectedPlan.provider.id,
          name: selectedPlan.provider.name,
        },
        couponId: coupon,
      },
    });
  }, [
    called,
    coupon,
    getCheckoutEstimate,
    selectedPlan.provider.id,
    selectedPlan.provider.name,
  ]);

  return { checkoutEstimate, loading, error };
};

export default useCheckoutEstimate;
