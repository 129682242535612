import { useMutation } from "@apollo/client";
import { INVOICE_COLLECT_PAYMENT } from "graphql/mutations";

export function useInvoiceCollectPayment(options: any) {
  const [invoiceCollectPayment, invoiceCollectPaymentResults] = useMutation(
    INVOICE_COLLECT_PAYMENT,
    options
  );

  return [
    ({ variables }: any) => {
      const { invoiceId, gatewayProvider, type, token } = variables;

      return invoiceCollectPayment({
        variables: {
          invoiceId,
          gateway: {
            provider: gatewayProvider,
            token,
          },
          type,
        },
      });
    },
    invoiceCollectPaymentResults,
  ];
}
